<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - iDirect IP Info</h3>
		</div>
		<div class="card-body pt-2">
			<b-table
				:fields="fields"
				:items="iplist"
				:perPage="showAll || curcount == 0 ? 0 : curcount"
				striped
				hover
			>
				<template #cell(InterfaceId)="data">
					{{ data.item.InterfaceId }}
					<span v-if="data.item.NATEnabled" class="bg-primary">NAT</span>
				</template>
				<template #cell(RIPEnabled)="data">
					<span class="svg-icon svg-icon-lg" v-if="data.item.RIPEnabled">
						<inline-svg src="/media/svg/icons/Navigation/Check.svg" />
					</span>
				</template>
				<template #cell(DHCPEnabled)="data">
					<span class="svg-icon svg-icon-lg" v-if="data.item.DHCPEnabled">
						<inline-svg src="/media/svg/icons/Navigation/Check.svg" />
					</span>
				</template>
				<template #cell(routes)="data">
					<span
						class="svg-icon svg-icon-lg"
						v-if="data.item.routes"
						v-b-tooltip.hover.bottom="data.item.routes.replace('<br>', '')"
					>
						<inline-svg src="/media/svg/icons/Navigation/Check.svg" />
					</span>
				</template>
				<template #cell(NetworkName)="data">
					{{ data.item.NetworkName }}
					<span
						class="svg-icon svg-icon-lg"
						v-if="currentnet == data.item.NetworkId"
						v-b-tooltip.hover.bottom="'Current Network'"
					>
						<inline-svg src="/media/svg/icons/General/Star.svg" />
					</span>
				</template>
			</b-table>
			<a
				style="cursor:pointer;"
				class="text-primary text-center"
				@click="showAll = !showAll"
				v-if="currentnet && curcount != ipinfo.length"
				>{{ showAll ? 'Hide Others' : 'Show All' }}</a
			>
		</div>
	</div>
</template>

<script>
export default {
	name: 'IdirectIPInfo',
	props: ['name', 'ipinfo', 'currentnet', 'updated'],
	data() {
		return {
			iplist: [],
			curcount: 0,
			showAll: false,
			fields: [
				{
					key: 'InterfaceId',
					label: 'Interface',
				},
				{ key: 'IpAddr', label: 'IP' },
				{ key: 'VLanID', label: 'VLAN' },
				{ key: 'RIPEnabled', label: 'RIP' },
				{ key: 'DHCPEnabled', label: 'DHCP' },
				{ key: 'routes', label: 'Routes' },
				{ key: 'NetworkName', label: 'Network' },
				{ key: 'TeleportName', label: 'Teleport' },
			],
		};
	},
	methods: {
		load() {
			this.iplist = this.ipinfo;
			if (this.currentnet) {
				var currentlist = [];
				for (var i = 0; i < this.iplist.length; i++) {
					if (this.iplist[i].NetworkId == this.currentnet) {
						currentlist.push(this.iplist[i]);
						this.iplist.splice(i, 1);
						i--;
						this.curcount++;
					}
				}
				this.iplist = currentlist.concat(this.iplist);
			}
		},
	},
	watch: {
		updated: function() {
			this.load();
		},
	},
	created() {
		this.load();
	},
};
</script>

<style></style>
